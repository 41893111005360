import KFC_Loader_Gif from 'styles/staticAssets/images/KFC_Loader_Gif.gif';
import Non_veg_dot_Icon from 'styles/staticAssets/images/Non_veg_dot_Icon.svg';
import Veg_dot_Icon from 'styles/staticAssets/images/Veg_dot_Icon.svg';
import Product_Card_Icon from 'styles/staticAssets/images/Product_Card_Icon.svg';
import ribbonRedOutlined from 'styles/staticAssets/images/ribbonRedOutlined.svg';
import TimerIcon from 'styles/staticAssets/images/TimerIcon.svg';
import LangGlobeHeader from 'styles/staticAssets/images/globe-black.svg';
import LangGlobeFooter from 'styles/staticAssets/images/globe-white.svg';
import heartIcon_activeFavourite from 'styles/staticAssets/images/heartIcon_activeFavourite.svg';
import heartIcon_active from 'styles/staticAssets/images/heartIcon_active_white.svg';
import heartIcon_active_red from 'styles/staticAssets/images/heartIcon_active_red.svg';
import heartIcon_inactive_red from 'styles/staticAssets/images/heartIcon_inactiveFavourite.svg';
import BucketIcon from 'styles/staticAssets/images/Icon_Add_to_Cart.svg';
import ConfirmedImg from 'styles/staticAssets/images/Confirmed.svg';
import TakeAwayImg from 'styles/staticAssets/images/Takeaway_Inprogress.svg';
import DeliveryTracker from 'styles/staticAssets/images/DeliveryTracker.svg';
import DeliveryCart from 'styles/staticAssets/images/DeliveryCart.svg';
import TakeawayCompleted from 'styles/staticAssets/images/Takeaway_Completed.svg';
import phoneImg from 'styles/staticAssets/images/Call.svg';
import Direction from 'styles/staticAssets/images/Direction.svg';
import CallIcon from 'styles/staticAssets/images/Call-white.svg';
import ArrowIcon from 'styles/staticAssets/images/GoBackIcon.svg';
import HeaderLogo from 'styles/staticAssets/images/kfcLogo.svg';
import PDPEditIconDisabled from 'styles/staticAssets/images/PDPEditIconDisabled.svg';
import PDPEditIcon from 'styles/staticAssets/images/PDPEditIcon.svg';
import ErrorImage from 'styles/staticAssets/images/Error-Icon.png';
import GiftCard_Cart from 'styles/staticAssets/images/GiftCard_Cart.svg';
import PopupCloseBtn from 'styles/staticAssets/images/PopupCloseBtn.svg';
import LocationImage from 'styles/staticAssets/images/Location.svg';
import GoogleMapLocationImage from 'styles/staticAssets/images/GoogleMapLocation.svg';
import SuggestionItemSearchPin from 'styles/staticAssets/images/SuggestionItemSearchPin.svg';
import clearTextIcon from 'styles/staticAssets/images/clearTextIcon.jpg';
import Time_Clock from 'styles/staticAssets/images/Time_Clock.svg';
import Clock from 'styles/staticAssets/images/clock.svg';
import Error from 'styles/staticAssets/images/Error.svg';
import MapImage from 'styles/staticAssets/images/Map.svg';
import UseLocationIcon from 'styles/staticAssets/images/useLocationIcon.svg';
import UseLocationWhiteIcon from 'styles/staticAssets/images/useLocationWhiteIcon.svg';
import Union from 'styles/staticAssets/images/Union.svg';
import LocationError from 'styles/staticAssets/images/Location_error.svg';
import StripsIcon from 'styles/staticAssets/images/Stripes_Small_OffersIcon.svg';
import Strips from 'styles/staticAssets/images/Strips.svg';
import CartError from 'styles/staticAssets/images/Cart_Error.svg';
import Check_Icon from 'styles/staticAssets/images/Check_Icon.svg';
import Multiselect_Check_Icon from 'styles/staticAssets/images/Multiselect_Check_Icon.svg';
import Multiselect_Uncheck_Icon from 'styles/staticAssets/images/Multiselect_Uncheck_Icon.svg';
import Selection_Checkbox_off from 'styles/staticAssets/images/Selection_Checkbox_off.svg';
import Ellipse from 'styles/staticAssets/images/Ellipse.svg';
import Ellipse_Orange from 'styles/staticAssets/images/Ellipse_Orange.svg';
import LockIcon from 'styles/staticAssets/images/Lock.svg';
import LocationOnIcon from 'styles/staticAssets/images/LocationOn.svg';
import WatchLaterIcon from 'styles/staticAssets/images/WatchLater.svg';
import Strips_Large_Cart from 'styles/staticAssets/images/Strips_Large_Cart.png';
import cart_header_mobile from 'styles/staticAssets/images/cart_header_mobile.png';
import CircleCloseButton from 'styles/staticAssets/images/Circle-close-button.svg';
import Error_Yellow from 'styles/staticAssets/images/Error_Yellow.svg';
import RedMarker from 'styles/staticAssets/images/RedMarker.svg';
import RedMarkerNoText from 'styles/staticAssets/images/RedMarkerNoText.svg';
import BlackMarker from 'styles/staticAssets/images/BlackMarker.svg';
import MarkerCustomer from 'styles/staticAssets/images/MarkerCustomer.svg';
import MarkerKFC from 'styles/staticAssets/images/MarkerKFC.svg';
import MarkerRaider from 'styles/staticAssets/images/MarkerRaider.svg';
import SingleArrowback from 'styles/staticAssets/images/SingleArrowback.svg';
import tickWhiteToast from 'styles/staticAssets/images/tickWhiteToast.svg';
import GreenTickImage from 'styles/staticAssets/images/GREEN_RIGHT_TICK.svg';
import FavoriteFillImage from 'styles/staticAssets/images/favorite-filled.svg';
import FavoriteUnfillImage from 'styles/staticAssets/images/favorite-unfilled.svg';
import Arrow from 'styles/staticAssets/images/Arrow.svg';
import ViewDetailsArrow from 'styles/staticAssets/images/ViewDetailsArrow.svg';
import ViewDetailsArrowBlack from 'styles/staticAssets/images/ViewDetailsArrow_black.svg';
import RedFilledRibbon from 'styles/staticAssets/images/RedFilled_Ribbon.svg';
import RedFilledBigRibbon from 'styles/staticAssets/images/RedFilled_Big_Ribbon.svg';
import MessageWhite from 'styles/staticAssets/images/Message_White.svg';
import SaveNotifyTick from 'styles/staticAssets/images/SaveNotifyTick.png';
import CTA_Arrow from 'styles/staticAssets/images/CTA_Arrow.svg';
import AccordianArrow from 'styles/staticAssets/images/Accordian_Arrow.png';
import AccordianUpArrow from 'styles/staticAssets/images/Accordian_Up_Arrow.png';
import AccordianDownDark from 'styles/staticAssets/images/AccordianDownDark.png';
import AccordianUpDark from 'styles/staticAssets/images/AccordianUpDark.png';
import ScheduledIcon from 'styles/staticAssets/images/Calender.svg';
import Reload from 'styles/staticAssets/images/Reload.svg';
import Refresh from 'styles/staticAssets/images/refresh.png';
import Favourite from 'styles/staticAssets/images/Favourite.svg';
import FavouriteInActiveBlack from 'styles/staticAssets/images/heart_icon_inactive_black.svg';
import FavouriteActiveBlack from 'styles/staticAssets/images/favourite_active_black.svg';
import FavoriteActiveWhite from 'styles/staticAssets/images/heart_favIcon_active_white.svg';
import AccordianDownBlack from 'styles/staticAssets/images/AccordianDownBlack.svg';
import AccordianUPBlack from 'styles/staticAssets/images/AccordianUPBlack.svg';
import EmptyCartImage from 'styles/staticAssets/images/empty_cart.png';
import FavoriteInActiveWhite from 'styles/staticAssets/images/heartIcon_inactive.svg';
import StripsIconMobile from 'styles/staticAssets/images/Stripes_mobile_OffersIcon.svg';
import SignInCard from 'styles/staticAssets/images/SignInCard.svg';
import PromosTostIcon from 'styles/staticAssets/images/PromosTostIcon.svg';
import GreenCheck from 'styles/staticAssets/images/GreenCheck.svg';
import Applied_CouponIcon from 'styles/staticAssets/images/Applied_CouponIcon.svg';
import Issue from 'styles/staticAssets/images/Issue.svg';
import BucketIssue from 'styles/staticAssets/images/Bucket_Issue.svg';
import SingleArrow from 'styles/staticAssets/images/dropdownArrowBlack.svg';
import offer_deals_banner from 'styles/staticAssets/images/offer_deals_banner.svg';
import TimerRibbonRedOutlined from 'styles/staticAssets/images/TimerRibbon.svg';
import Promos_Coupon_Icon from 'styles/staticAssets/images/Promo_Coupon_Icon.svg';
import WhiteExclamatry from 'styles/staticAssets/images/WhiteExclamatry.svg';
import Tooltip from 'styles/staticAssets/images/Tooltip.svg';
import Clear_icon from 'styles/staticAssets/images/Clear_icon.svg';
import StripesIcon from 'styles/staticAssets/images/Stripes_OffersIcon.svg';
import StripesIconWhite from 'styles/staticAssets/images/StripsWhite.svg';
import HelpIcon from 'styles/staticAssets/images/Help.png';
import RestRoom from 'styles/staticAssets/images/Rest_Room.svg';
import Accessibility_Dark from 'styles/staticAssets/images/Accessibility_Dark.svg';
import Wifi_Dark from 'styles/staticAssets/images/Wifi_Dark.svg';
import Catering from 'styles/staticAssets/images/Catering.svg';
import KFCbucket from 'styles/staticAssets/images/KFCbucket.svg';
import External_url from 'styles/staticAssets/images/External_url.svg';
import fingerlickingDesk from 'styles/staticAssets/images/Finger_lickInDesk.svg';
import fingerlickingMob from 'styles/staticAssets/images/Finger_lickInMob.svg';
import OfferTag from 'styles/staticAssets/images/offer_tag.svg';
import PromoTag from 'styles/staticAssets/images/promo_tag.png';
import Polygon from 'styles/staticAssets/images/Polygon.png';
import DarkCloseButton from 'styles/staticAssets/images/Darkclosebutton.svg';
import WhiteCloseButton from 'styles/staticAssets/images/LightwhiteCrossClose.svg';
import SearchIcon from 'styles/staticAssets/images/searchIcon.svg';
import SearchIconGrey from 'styles/staticAssets/images/searchIconGrey.svg';
import RightQuotes from 'styles/staticAssets/images/RightQuotes.svg';
import LeftQuotes from 'styles/staticAssets/images/LeftQuotes.svg';
import Timeout from 'styles/staticAssets/images/Timeout.svg';
import Timeout_Red from 'styles/staticAssets/images/Timeout_Red.svg';
import Error_Red from 'styles/staticAssets/images/Error_Red.svg';
import Error_Black from 'styles/staticAssets/images/Error_Black.svg';
import RoundTheClockDark from 'styles/staticAssets/images/amenities/24x7.svg';
import BirthdayDark from 'styles/staticAssets/images/amenities/birthday.svg';
import BreakfastDark from 'styles/staticAssets/images/amenities/breakfast.svg';
import ContactlessDark from 'styles/staticAssets/images/amenities/contactless.svg';
import DrivethruDark from 'styles/staticAssets/images/amenities/driveThru.svg';
import FoodCourtDark from 'styles/staticAssets/images/amenities/foodCourt.svg';
import WifiDark from 'styles/staticAssets/images/amenities/freeWifi.svg';
import KiosksDark from 'styles/staticAssets/images/amenities/kiosks.svg';
import ParkingDark from 'styles/staticAssets/images/amenities/parking.svg';
import PlaygroundDark from 'styles/staticAssets/images/amenities/playground.svg';
import RefillDark from 'styles/staticAssets/images/amenities/refill.svg';
import TerracesDark from 'styles/staticAssets/images/amenities/terraces.svg';
import AccessibilityDark from 'styles/staticAssets/images/amenities/wheelchairAccess.svg';
import RestRoomDark from 'styles/staticAssets/images/amenities/genderNeutralRestroomDark.svg';
import clockFeature from 'styles/staticAssets/images/clock_feature.svg';
import MobSearchIcon from 'styles/staticAssets/images/mobileSearch.svg';
import ClearIconDark from 'styles/staticAssets/images/clearIconDark.svg';
import CrossCloseIcon from 'styles/staticAssets/images/crossCloseIcon.svg';
import ConfirmedSignOut from 'styles/staticAssets/images/confirmedSignOut.svg';
import Calendar from 'styles/staticAssets/images/Calendar.svg';
import SingleArrowBlack from 'styles/staticAssets/images/Single_Arrow_Black.svg';
import SingleArrowBlackExpand from 'styles/staticAssets/images/Single_Arrow_Black_Expand.svg';
import LocationWithoutError from 'styles/staticAssets/images/Location_without_error.svg';
import ArrowOrderUp from 'styles/staticAssets/images/Arrow_Order_Up.svg';
import ArrowOrderDown from 'styles/staticAssets/images/Arrow_Order_Down.svg';
import Stripelarge from 'styles/staticAssets/images/Stripelarge.svg';
import PictogramAlertIcon from 'styles/staticAssets/images/pictogram-alert-icon.svg';
import Clock_Dark from 'styles/staticAssets/images/Clock_Black.svg';
import InfoIcon from 'styles/staticAssets/images/icon.svg';
import TickWithCircle from 'styles/staticAssets/images/tickWithCircle.svg';
import voucher from 'styles/staticAssets/images/voucher.svg';
import ThankYou from 'styles/staticAssets/images/ThankYou.svg';
import dineIn from 'styles/staticAssets/images/dineIn.svg';

import StripesLoader from 'styles/staticAssets/images/Stripes_Loader.svg';
import ConfirmedImage from 'styles/staticAssets/images/confirmed_1.svg';
import RightArrowIcon from 'styles/staticAssets/images/Single_Arrow_Icon.svg';
import Selection_Customize from 'styles/staticAssets/images/Selection_Customize.svg';
import GoTop from 'styles/staticAssets/images/GoTop.svg';
import ReorderIcon from 'styles/staticAssets/images/Reorder.svg';
import BannerCloseButton from 'styles/staticAssets/images/closeButtonBanner.svg';
import Call_Icon from 'styles/staticAssets/images/Call_Icon.svg';
import MailIcon from 'styles/staticAssets/images/Mail_Icon.svg';
import OffersCoupnIcon from 'styles/staticAssets/images/Offers_Coupon_Icon.svg';
import Time_ClockDark from 'styles/staticAssets/images/Time_ClockDark.svg';
import Single_Arrow_Edit from 'styles/staticAssets/images/Single_Arrow_Edit.svg';
import FindStoreImage from 'styles/staticAssets/images/Native_select a kfc_grey image.svg';
import Instagram from 'styles/staticAssets/images/Instagram.svg';
import Facebook from 'styles/staticAssets/images/Facebook.svg';
import Twitter from 'styles/staticAssets/images/Twitter.svg';
import apple from 'styles/staticAssets/images/apple.svg';
import GoogleplayIcon from 'styles/staticAssets/images/GoogleplayIcon.svg';
import Finger_Lickin_Image from 'styles/staticAssets/images/finger_lickin.svg';
import BlackRightArrow from 'styles/staticAssets/images/black_right_arrow.svg';
import getHelp from 'styles/staticAssets/images/getHelp.svg';
import GiftcardSuccessImg from 'styles/staticAssets/images/GiftCard.svg';
import GiftCardFailureImg from 'styles/staticAssets/images/purchasefailure.svg';
import HamburgerMenuIcon from 'styles/staticAssets/images/MenuIcon.svg';
import Arrow_Icon from 'styles/staticAssets/images/ArrowIcon.svg';
import CartIcon from 'styles/staticAssets/images/Bucket-Cart.svg';
import Account from 'styles/staticAssets/images/Icon_Account.svg';
import View_1UP_Icon from 'styles/staticAssets/images/View_1UP_Icon.svg';
import View_1UP_Icon_Inactive from 'styles/staticAssets/images/View_1UP_Icon_Inactive.svg';
import View_carousal_Icon from 'styles/staticAssets/images/View_carousal_Icon.svg';
import View_carousal_Icon_Inactive from 'styles/staticAssets/images/View_carousal_Icon_Inactive.svg';
import kfcCup from 'styles/staticAssets/images/kfcCup.svg';
import kfc_bucket_mob from 'styles/staticAssets/images/kfc_bucket_mob.svg';
import clock from 'styles/staticAssets/images/Scheduled_badge.svg';
import HamburgerDownArrow from 'styles/staticAssets/images/HamburgerDownArrow.svg';
import filter_options_stoke from 'styles/staticAssets/images/filter_options_stoke.svg';
import SearchPin from 'styles/staticAssets/images/Search_Pin.svg';
import SuccessImg from 'styles/staticAssets/images/successSign.svg';
import errorIcon from 'styles/staticAssets/images/Icon_Alert.svg';
import PontaLogo from 'styles/staticAssets/images/ponta_logo.png';
import RakutenLogo from 'styles/staticAssets/images/rakuten_logo.png';
import CrownRankLogo from 'styles/staticAssets/images/crown-rank.svg';
import IconGold from 'styles/staticAssets/images/icon-gold.svg';
import IconSilver from 'styles/staticAssets/images/icon-silver.svg';
import IconBronze from 'styles/staticAssets/images/icon-bronze.svg';
import pickup_instore from 'styles/staticAssets/images/pickup_instore.svg';
import pickup_drivethru from 'styles/staticAssets/images/pickup_drivethru.svg';
import pickup_curbside from 'styles/staticAssets/images/pickup_curbside.svg';
import CarIcon from 'styles/staticAssets/images/car.svg';
import ShoppingBagIcon from 'styles/staticAssets/images/shoppingBag.svg';
import RedArrowIcon from 'styles/staticAssets/images/red-arrow.svg';
import RewardTextIcon from 'styles/staticAssets/images/reward-text.svg';
import CheckGreen from 'styles/staticAssets/images/check-green.svg';

export const IMAGE_PATH = {
  errorIcon,
  SuccessImg,
  SearchPin,
  HamburgerDownArrow,
  filter_options_stoke,
  clock,
  kfcCup,
  kfc_bucket_mob,
  View_1UP_Icon,
  View_1UP_Icon_Inactive,
  View_carousal_Icon,
  View_carousal_Icon_Inactive,
  CartIcon,
  Account,
  pickup_instore,
  pickup_drivethru,
  Arrow_Icon,
  pickup_curbside,
  HamburgerMenuIcon,
  GiftcardSuccessImg,
  GiftCardFailureImg,
  getHelp,
  Finger_Lickin_Image,
  BlackRightArrow,
  Instagram,
  Facebook,
  Twitter,
  apple,
  GoogleplayIcon,
  FindStoreImage,
  Single_Arrow_Edit,
  Time_ClockDark,
  OffersCoupnIcon,
  MailIcon,
  Call_Icon,
  BannerCloseButton,
  ReorderIcon,
  GoTop,
  Selection_Customize,
  RightArrowIcon,
  ConfirmedImage,
  Timeout,
  Timeout_Red,
  fingerlickingDesk,
  fingerlickingMob,
  KFC_Loader_Gif,
  Non_veg_dot_Icon,
  Veg_dot_Icon,
  Product_Card_Icon,
  ribbonRedOutlined,
  TimerIcon,
  heartIcon_active,
  heartIcon_active_red,
  heartIcon_inactive_red,
  heartIcon_activeFavourite,
  BucketIcon,
  ConfirmedImg,
  TakeAwayImg,
  DeliveryTracker,
  DeliveryCart,
  TakeawayCompleted,
  phoneImg,
  Direction,
  CallIcon,
  ArrowIcon,
  HeaderLogo,
  PDPEditIconDisabled,
  PDPEditIcon,
  ErrorImage,
  PopupCloseBtn,
  LocationImage,
  GoogleMapLocationImage,
  SuggestionItemSearchPin,
  clearTextIcon,
  Time_Clock,
  Error,
  MapImage,
  UseLocationIcon,
  UseLocationWhiteIcon,
  Union,
  LocationError,
  StripsIcon,
  StripesIcon,
  Strips,
  CartError,
  Check_Icon,
  Selection_Checkbox_off,
  Ellipse,
  LockIcon,
  LocationOnIcon,
  WatchLaterIcon,
  Strips_Large_Cart,
  cart_header_mobile,
  CircleCloseButton,
  Error_Yellow,
  SingleArrowback,
  tickWhiteToast,
  GreenTickImage,
  FavoriteFillImage,
  Multiselect_Check_Icon,
  Multiselect_Uncheck_Icon,
  RedMarker,
  RedMarkerNoText,
  BlackMarker,
  MarkerCustomer,
  MarkerKFC,
  MarkerRaider,
  Arrow,
  RedFilledRibbon,
  ViewDetailsArrow,
  ViewDetailsArrowBlack,
  MessageWhite,
  SaveNotifyTick,
  CTA_Arrow,
  AccordianArrow,
  AccordianUpArrow,
  AccordianDownDark,
  AccordianUpDark,
  ScheduledIcon,
  Reload,
  Refresh,
  Favourite,
  FavouriteActiveBlack,
  FavouriteInActiveBlack,
  FavoriteActiveWhite,
  AccordianDownBlack,
  AccordianUPBlack,
  EmptyCartImage,
  FavoriteInActiveWhite,
  StripsIconMobile,
  SignInCard,
  PromosTostIcon,
  GreenCheck,
  Applied_CouponIcon,
  Issue,
  SingleArrow,
  offer_deals_banner,
  TimerRibbonRedOutlined,
  Promos_Coupon_Icon,
  WhiteExclamatry,
  RedFilledBigRibbon,
  Tooltip,
  Clear_icon,
  HelpIcon,
  FavoriteUnfillImage,
  RestRoom,
  Accessibility_Dark,
  Wifi_Dark,
  Catering,
  KFCbucket,
  External_url,
  OfferTag,
  PromoTag,
  Polygon,
  DarkCloseButton,
  WhiteCloseButton,
  SearchIcon,
  SearchIconGrey,
  RightQuotes,
  LeftQuotes,
  StripesIconWhite,
  Error_Red,
  Error_Black,
  RoundTheClockDark,
  BirthdayDark,
  BreakfastDark,
  ContactlessDark,
  DrivethruDark,
  FoodCourtDark,
  WifiDark,
  KiosksDark,
  ParkingDark,
  PlaygroundDark,
  RefillDark,
  TerracesDark,
  AccessibilityDark,
  RestRoomDark,
  MobSearchIcon,
  clockFeature,
  ClearIconDark,
  CrossCloseIcon,
  ConfirmedSignOut,
  Calendar,
  SingleArrowBlack,
  SingleArrowBlackExpand,
  LocationWithoutError,
  ArrowOrderUp,
  ArrowOrderDown,
  Stripelarge,
  PictogramAlertIcon,
  Clock,
  Clock_Dark,
  InfoIcon,
  StripesLoader,
  LangGlobeHeader,
  LangGlobeFooter,
  TickWithCircle,
  GiftCard_Cart,
  voucher,
  Ellipse_Orange,
  BucketIssue,
  ThankYou,
  PontaLogo,
  RakutenLogo,
  CrownRankLogo,
  IconGold,
  IconSilver,
  IconBronze,
  CarIcon,
  ShoppingBagIcon,
  RedArrowIcon,
  RewardTextIcon,
  CheckGreen,
  dineIn,
};
